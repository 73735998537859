<template>
  <div class="text-left">
    <div class="row">
      <div class="col-12 col-md-4"><FilterBox :search="true" class="py-1"
        placeholder="Search Webhook Logs" v-model.trim="searchLog"></FilterBox></div>
        <div class="col-12 col-md-8">
           <div class="d-flex justify-content-between align-items-center">
                      <b-dropdown right class="" id="DashboardNav">
            <template slot="button-content">
          <div class="industry-btn" style="min-width:200px;">
            <div class="">

             <span class="pr-2"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 15.75V10.5M3 7.5V2.25M9 15.75V9M9 6V2.25M15 15.75V12M15 9V2.25M0.75 10.5H5.25M6.75 6H11.25M12.75 12H17.25" stroke="#73738D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</span>
              <span>{{ selectedCategory.title }}</span>
            </div>
            <div class="dropdown-chev">
           
     <span class="close-dropdown">
        <i class="fa-solid fa-angle-down"></i>
     </span>
              </div>
          </div>
            </template>
            <b-dropdown-item
            @click="()=>{selectedCategory=category;webhookFilter.webhooks=category.value?[category.value]:[]}"
              class="topbarLink d-flex align-items-center"
              v-for="category in [{title:'Alert Type',value:''},...webhookList]" :key="category.title" >
          
              {{ category.title }}</b-dropdown-item
            >
           
              
          </b-dropdown>
          <div class="">
            <ul class="status-list m-0">
                        <li  :class="[webhookFilter.status===''?'active disabled':'']" @click.stop="webhookFilter.status=''">All</li>
                        <li :class="[webhookFilter.status?'active disabled':'']" @click.stop="webhookFilter.status=true">Success</li>
                        <li :class="[webhookFilter.status===false?'active disabled':'']" @click.stop="webhookFilter.status=false">Error</li>                    
                      </ul>
          </div>
                    </div>
        </div>
     
    </div>
         
              <div class="loader d-flex align-items-center justify-content-between" v-if="isLoading" >
            <div class="d-flex align-items-center" >
              <b-spinner small style="color: #4d1b7e"></b-spinner>
              <span class="ml-2">Loading Webhook Logs</span>
            </div>
          </div>
      <div class="" style="min-height: 600px" v-if="isLoadingFirstTime">
              <b-skeleton-table
                :rows="5"
                :columns="3"
                :table-props="{ bordered: true }"></b-skeleton-table>
              <LoaderComp v-if="false" />
            </div>
            <div class="mt-3" v-else>
              
                <b-table
                  class="webhook-table"
                  hover
                  v-if="data.length"
                  :items="data"
                  :fields="fields">
                  <template #cell(created_at)="data">
              {{ formattedDate(data.item.created_at) }}
            </template>
                  <template #cell(event_title)="data">
                   {{ data.item.webhook.event_title }}
                  </template>
                   <template #cell(response_status)="data">
                    <div class="quizell-badge" :class="[data.item.response_status=='success'?'quizell-badge-active':'quizell-badge-inactive']">
                      {{ data.item.response_status=='fail'?'Error':'Success' }}
                    </div>
                     
                   
                  </template>
                </b-table>
           
                <template v-else>
                  <div class="d-flex justify-content-center align-items-center" style="height: 50vh;" >

<EmptyTable >
  <template #title>
    No Webhook log found
  </template>
  <template #description>
    Time to fill your shelves or refine your search. Remember, your results will appear right here.
  </template>
</EmptyTable>
</div>
          </template>

            <QuizellPagination  :total-rows="totalRows" :currentPage="currentPage"
            :per-page="perPage"   v-if="data.length" @update="updatePagination" @UpdatePerPage="updatePerPage"></QuizellPagination>
            </div>
  </div>
</template>
<script>
import EmptyTable from "../Layout/EmptyTable.vue";
import { mapGetters } from "vuex";
import FilterBox from '../Layout/FilterBox.vue';
import { debounce } from "lodash";
import QuizellPagination from "../Layout/QuizellPagination.vue";
export default {
 components:{
  FilterBox,
  EmptyTable,
  QuizellPagination
 },
watch:{
  searchLog() {
      this.handleSearchLog(
      );
    },
    webhookFilter:{
      handler(){
        this.getLogs();
      },
      deep:true
    }
},

  data() {
    return {
      selectedCategory:{title:'Alert Type',value:''},
      searchLog:'',
        currentPage: 1,
     perPage: 10,
      totalRows: 20,
      isLoading:false,
      activeTab: "webhook-list",
     isLoadingFirstTime:true,
     webhookList:[],

      fields: [
        {
          key: "event_title",
          label: "Event",
           tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading text-start",
        },
         {
          key: "event_id",
          label: "Event ID",
           tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading text-start",
        },
        {
          key: "response_status",
          label: "Response Status",
           tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading text-start",
        },
        {
          key: "created_at",
          label: "Created Date",
           tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading text-start",
        },
      ],
      webhookFilter:{
        status:true,
        webhooks:[]
      },
      data: [],
    };
  },
  computed: {
    ...mapGetters(["GetTokenFromMetaTag"]),
  },
  methods: {
    updatePerPage(e){
      this.currentPage=1;
      this.perPage=e;
      this.getLogs();
    },
    updatePagination(e){
      this.currentPage=e;
      this.getLogs();
    },
    async getLogs(){
      this.isLoading=true;
      let filter = {...this.webhookFilter}
      if(filter.status==='')
    {
      delete filter.status
    }
 const response = await this.$store.dispatch('fetchData',{
    axiosMethod:"post",
    resource:'webhooks',
    method:'logList',
    params:{page:this.currentPage,search:this.searchLog,...filter}
  });
 
  if(response&&response.status=='success')
  {

    this.data=response.data.logs.data;
    this.webhookList=[];
   for (const property in response.data.webhooks) { 
      this.webhookList.push(
   {
    title:response.data.webhooks[property],
    value:parseInt(property)

   }
      )

   }
  
    this.totalRows =response.data.logs.total;
  }
   this.isLoading=false;
   this.isLoadingFirstTime=false;
  },
    formattedDate(timestamp) {
      const date = new Date(timestamp);

      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = String(date.getUTCFullYear());

      return `${day}-${month}-${year}`;
    },
    handleSearchLog: debounce(function () {
      this.getLogs();
    }, 500),
  },
  mounted() {
    this.getLogs();
  },
};
</script>

<style scoped>
#DashboardNav >>> button {
                      background: transparent;
                      border: none;
                      margin: 0;
                      padding: 0;
                    }
                    #DashboardNav >>> button::after {
                      display: none;
                    }
                    #DashboardNav >>> ul {
                      background: #ffffff;
                      box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
                      border-radius: 6px;
                    }
                    #DashboardNav >>> li.topbarLink {
                      color: #344054;
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      margin-bottom: 5px;
                    }
                    #DashboardNav >>> li a:active {
                      color: #fff;
                      text-decoration: none;
                      background-color: #4d1b7e;
                    }
                    .industry-btn{
                      display: flex;
                    padding: 10px 12px;
                    justify-content: space-between;
                    align-items: center;
                    gap: 4px;
                    border-radius: 6px;
                    border: 1px solid var(--border-color);
                    background: #F6F6F8;
                    color: var(--grey-button);
                    font-family: Poppins;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%; /* 19.5px */
                    }
                    </style>
                    <style>
                    .dropdown-chev{
                      transition:0.3s ease all;
                    }
                    .show > .btn > .industry-btn > .dropdown-chev {
                      transform :rotate(180deg)
                    }
                    :not(.show) > .btn > .industry-btn > .dropdown-chev 
                    {
                      transform :rotate(0deg)
                    }
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
.TitleHead {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;

  color: #18191c;
}
.EmptyKey {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 42px;
  text-align: center;
  color: #18191c;
}

.addToken {
  background: #4d1b7e;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  min-width: 170px;
  color: #ffffff;
}
.addToken:hover {
  color: #ffffff;
}
</style>
<style>
.table thead th {
  border-bottom: none !important;
  border-top: none !important;
}
.tab-item {
  cursor: pointer;
}
.webhook-active {
  position: relative;
  color: #4d1b7e;
  font-weight: bold;
}
.loader {
  min-height: 40px;
}
.webhook-active:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  background: #4d1b7e;
  bottom: -10px;
  left: 0px;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e0dee2 !important;
  border-color: #ced3d7 !important;
}

.custom-control-input.radio:checked ~ .custom-control-label::before {
  background-color: #0397cc !important;
  color: #0397cc !important;
  border-color: #0397cc !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-color: #ced3d7 !important;
}

.status-list .active{
  border-radius: 6px;
  background: #F6F6F8;
  color:var(--black);
  pointer-events: none;
}
.status-list li {
  color: var(--grey-button);
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 100%; /* 14px */
letter-spacing: -0.28px;
padding: 6px 16px;
text-transform: capitalize;
cursor:pointer;
transition: 0.3s ease all;
}
.status-list li:hover {
  background: #F6F6F8;
border-radius:6px;
}
.status-list{
  display:flex;
  align-items:center;
  padding:0px;
  list-style-type: none;
  gap:5px;
}
.status-list .active{
  border-radius: 6px;
  background: #F6F6F8;
  color:var(--black);
}
</style>
